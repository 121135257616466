.App {
  background-color: #9E9E9E;
  min-height: 100vh;
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #448AFF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Materialize CSS INPUT COLORS */
/* blue color = blue accent-2 */
/* label color */
.input-field label {
  color: #BDBDBD !important; 
}
/* label focus color */
.input-field input[type=text]:focus + label {
  color: #8bc34a !important;
}
/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #8bc34a !important;
  box-shadow: 0 1px 0 0 #8bc34a !important;
}

/* label focus color */
.input-field input[type=password]:focus + label {
  color: #8bc34a !important;
}
/* label underline focus color */
.input-field input[type=password]:focus {
  border-bottom: 1px solid #8bc34a !important;
  box-shadow: 0 1px 0 0 #8bc34a !important;
}

/* valid color */
.input-field input[type=text].valid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* invalid color */
.input-field input[type=text].invalid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #000 !important;
}

/* MATERIALIZE CSS COLOR FOR WAVES */

.waves-effect.waves-blue .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
   background-color: rgba(68,138,255, 0.65);
 }

 .waves-effect.waves-e2e-green .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
   background-color: rgba(139,195,74, 0.65);
 }


 /* UI KIT CSS MODIFIERS */

.uk-table td{
  padding: 5px 12px;
}

.uk-table th{
  //background: #7395AE !important;
  background-image: linear-gradient(to bottom, #AAD187 0%, #c9e6af 50%, #deecd0 100%);
  color: black !important;
}
 
 .uk-card-badge {
   background: #8bc34a !important;
 }

 /*
 * Button Green
 */
.uk-button-green {
  background-color: #8bc34a;
  color: #fff;
  border: 1px solid transparent;
}
/* Hover + Focus */
.uk-button-green:hover,
.uk-button-green:focus {
  background-color: #6e9c3a;
  color: #fff;
}
/* OnClick + Active */
.uk-button-green:active,
.uk-button-green.uk-active {
  background-color: #8bc34a;
  color: #fff;
}

.uk-button-green:disabled
{
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5;
}

.uk-input,
.uk-select:not([multiple]):not([size]){
  height:30px;
}

.uk-paginator-input{
  height: 25px;
  width: 50px;
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  overflow: visible;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

/* Focus INPUT, SELECT, TEXTAREA */
.uk-input:focus,
.uk-paginator-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #666;
  border-color: #8bc34a;
}

.uk-spinner {
  color: #8bc34a;
}

.uk-button {
  text-transform: capitalize;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 120px !important;
}

.min-width-0 {
  min-width: 0px !important;
}

.e2e {
  background-color: #ffffff;
}

/* UK TAB Active */
.uk-tab > .uk-active > a {
  color: #333;
  border-color: #8bc34a;
}

/* checkbox and radio button */

.uk-checkbox {
  margin-right: 15px;
  // margin-left: 15px;
}

/* Focus */
.uk-radio:focus, 
.uk-checkbox:focus {
  outline: none !important;
  border-color: #8bc34a !important;
}
/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #8bc34a !important;
  border-color: transparent !important;
}
/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #6e9c3a !important;
}

.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
}

.table-container {
  margin-top: 10px;
}

.task-property-border {
  border: 1px solid #e5e5e5 !important;
}

.border-table {
  border-left: 1px solid #e5e5e5 !important;
  border-right: 1px solid #e5e5e5 !important;
  margin-bottom: 10px;
}

.uk-table {
  border-top: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.uk-table td:not(:first-child) {
  border-left: 1px solid #e5e5e5;
}

.uk-table tr:not(:first-child) {
  border-left: 0 !important;
}

.uk-table th:not(:first-child) {
  border-left: 1px solid #e5e5e5;
}

.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/* Colors for Striped Tables */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

/*
 * Table Hover
 */
 .uk-table-hover > tr:hover,
 .uk-table-hover tbody tr:hover {
   background: #8bc34a33;
 }



/* Pagination items */
.uk-pagination-number {
  /* 1 */
  display: block;
  /* 2 */
  color: #999;
  transition: color 0.1s ease-in-out;
}
/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
 .uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  color: #999;
  transition: color 0.1s ease-in-out;
}
/* Hover + Focus */
.uk-pagination > * > :hover,
.uk-pagination > * > :focus {
  color: #6e9c3a;
  text-decoration: none;
}
/* Active */
.uk-pagination > .uk-active > * {
  color: #8bc34a;
  font-weight: bold;
}
/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999;
}


/* FOR LABELS */
.uk-form-label {
  color: #333;
  font-weight: bold;
  font-size: 0.750rem;
}



.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 1px;
  margin-top: 10px;
}

.uk-form-label-properties-task {
  color: #333;
  font-size: 0.750rem;
}

@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label-properties-task {
    display: block;
    margin-bottom: 5px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label-properties-task {
    width: 200px;
    margin-top: 7px;
    float: left;
  }
}

/* buttons */





/* HEADINGS*/

/* Headings
 ========================================================================== */
 h1,
 .uk-h1,
 h2,
 .uk-h2,
 h3,
 .uk-h3,
 h4,
 .uk-h4,
 h5,
 .uk-h5,
 h6,
 .uk-h6,
 .uk-heading-small,
 .uk-heading-medium,
 .uk-heading-large,
 .uk-heading-xlarge,
 .uk-heading-2xlarge {
   margin: 0 0 10px 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-weight: normal;
   color: #333;
   text-transform: none;
 }


 /* For day picker */
 .DayPickerInput-OverlayWrapper {
  position: relative;
  bottom: 375px;
}

/* number inputs*/
.input-number {
  text-align:right !important;
}

.loading-box {
  margin: 20px;
}

fieldset {
  margin: 0 0 0 0 !important;
}

.uk-button-min-font {
  font-size: 0.875rem !important; 
}

.grid-button {
  line-height: 25px;
}

.property-box {
  margin-bottom: 10px;
  margin-left: 10px;
  outline: 1px solid #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.uk-width-1-12 {
  width: calc(100% * 1 / 12);
}
.uk-width-2-12 {
  width: calc(100% * 2 / 12);
}
.uk-width-3-12 {
  width: calc(100% * 3 / 12);
}
.uk-width-4-12 {
  width: calc(100% * 4 / 12);
}
.uk-width-5-12 {
  width: calc(100% * 5 / 12);
}
.uk-width-6-12 {
  width: calc(100% * 6 / 12);
}
.uk-width-7-12 {
  width: calc(100% * 7 / 12);
}
.uk-width-8-12 {
  width: calc(100% * 8 / 12);
}
.uk-width-9-12 {
  width: calc(100% * 9 / 12);
}
.uk-width-10-12 {
  width: calc(100% * 10 / 12);
}
.uk-width-11-12 {
  width: calc(100% * 11 / 12);
}
.uk-width-12-12 {
  width: 100%;
}

.uk-hr{
  border-top: 1px solid #000000;
  
}

.uk-hr-vertical{
  border-left: 1px solid #000000;
}


@media (min-width: 720px) {
  .uk-form-horizontal .uk-form-label-properties-task {
      width: 235px;
      margin-top: 7px;
      float: left;
  }
  .uk-form-horizontal .uk-form-label-properties-task-filter {
    width: 50px;
    margin-top: 3px;
    float: left;
}
  .uk-form-horizontal .uk-form-controls {
    margin-left: 250px;
  }

  .uk-form-horizontal .uk-form-controls-filter {
    margin-left: 50px;
  }
}

.icon-container-properties {
  padding-left: 0px !important;
}

.container-filter-properties {
  padding-left: 50px !important;
}

.icons-container-properties {
  margin-left: 0px !important;
}

.filter-label {
  padding-left: 0 !important;
}

.warning-color{
  color: red;
}

.uk-divider-vertical{
  height: 100%;
}

button.uk-accordion-title{
  width: 100%;
  text-align: left;
  //background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  //font-weight: bold;
  margin-bottom: 0px;
  border-bottom: 1px solid #e5e5e5;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  //color: #333;
  background-image: linear-gradient(to right, #8ec055 0%, #a3ce73 50%, #d1e9b6 100%);
  color: white;
}

.uk-accordion-title:focus, .uk-accordion-title:hover {
  //color: #666;
  text-decoration: none;
  outline: 0;
  background-color: rgb(232, 243, 219);
  color: white;
  cursor: pointer;
}

button.uk-accordion-title:hover{
  //background-color: rgb(232, 243, 219);
  color: white;
  //background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 70%,  #8ec055 100%);
  background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 50%, #d1e9b6 100%);
  background-position: right center;
}

/*button.uk-accordion-title:focus{
  //background-color: rgb(232, 243, 219);
  color: white;
  //background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 70%,  #8ec055 100%);
  background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 50%, #d1e9b6 100%);
  background-position: right center;
}*/

div.uk-accordion-content{
  padding-left: 20px;
  //align-content: center;
  //border-left: 1px solid #e5e5e5;
  //border-right: 1px solid #e5e5e5;
  //background-color: red;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 15px;
  margin-top: 0px;
}
.uk-accordion-content{
  padding-top: 0px;
}

.uk-accordion > :nth-child(n+2) {
  margin-top: 0px;
}

.uk-accordion-content>:last-child {
  //margin-bottom: 0;
  //margin-top: 0px;
}

.uk-open > .uk-accordion-title {
  //background-color: rgb(232, 243, 219);
  background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 50%, #d1e9b6 100%);
  color: white;
}

.container-note-message {
  padding-left: 50px !important;
}

input[type=color]{
	width: 100%;
	height: 100%;
	border: none;
	//border-radius: 40px;
	background: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	//border: solid 1px #000; /*change color of the swatch border here*/
  //border-radius: 40px;
  border: none;
}

.uk-grid>.uk-grid-margin{
  margin-top: 10px;
}

.propoerties-tasks-category-title {
  background-image: linear-gradient(to right, #8ec055 0%, #a3ce73 50%, #d1e9b6 100%);
  color: white;
  padding-left: 10px;
  margin: 0 0 0 0 !important;
}

.propoerties-tasks-category-title:hover {
  background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 50%, #d1e9b6 100%);
}

.properties-tasks-category-title-new1 {
  background-image: linear-gradient(to right, #116466 0%, #437c7e 50%, #8eafaf 100%);
  color: white;
  font-size: large;
  line-height: 50px;
  padding-left: 10px;
  height: 50px;
  margin: 0 0 10px 0 !important;
}

.properties-tasks-category-title-new1:hover {
  background-image: linear-gradient(to right, #116466 100%, #437c7e 50%, #8eafaf 100%);
}